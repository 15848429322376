import * as Yup from "yup";

export const weightSchema = Yup.object({
         min: Yup.number()
           .required()
           .integer()
           .lessThan(Yup.ref("max"))
           .lessThan(250001, "min must be 250000 or smaller")
           .moreThan(-1, "min must be 0 or greater"),
         max: Yup.number()
           .required()
           .integer()
           .moreThan(Yup.ref("min"))
           .lessThan(250001, "max must be 250000 or smaller")
           .moreThan(-1, "max must be 0 or greater")
       });

export const elevationAngleSchema = Yup.object({
         min: Yup.number()
           .required()
           .integer()
           .lessThan(Yup.ref("max"))
           .lessThan(361, "min must be 360 or smaller")
           .moreThan(-1, "min must be 0 or greater"),
         max: Yup.number()
           .required()
           .integer()
           .moreThan(-1, "max must be 0 or greater")
           .moreThan(Yup.ref("min"))
           .lessThan(361, "max must be 360 or smaller")
       });

export const spinAngleSchema = Yup.object({
         min: Yup.number()
           .required()
           .integer()
           .lessThan(Yup.ref("max"))
           .lessThan(361, "min must be 360 or smaller")
           .moreThan(-1, "min must be 0 or greater"),
         max: Yup.number()
           .required()
           .integer()
           .moreThan(-1, "max must be 0 or greater")
           .moreThan(Yup.ref("min"))
           .lessThan(361, "max must be 360 or smaller")
       });

export const dimensionSchema = Yup.object({
         min: Yup.number()
           .required()
           .integer()
           .lessThan(Yup.ref("max"))
           .lessThan(701, "min must be 700 or smaller")
           .moreThan(-1, "min must be 0 or greater"),
         max: Yup.number()
           .required()
           .integer()
           .moreThan(-1, "max must be 0 or greater")
           .moreThan(Yup.ref("min"))
           .lessThan(701, "max must be 700 or smaller")
       });
