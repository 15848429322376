import React from "../../../node_modules/react";
import {
  List,
  TextField,
  ListItem,
  ListItemText
} from "../../../node_modules/@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Range from "rc-slider/lib/Range";
import Divider from "@material-ui/core/Divider";
import { KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import "rc-slider/assets/index.css";
import { Formik, ErrorMessage } from "formik";



let minMaxForms = (classes, id) => ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  submitForm
}) => (
  <div>
    <form onSubmit={handleSubmit} className={classes.form}>
      <TextField
        id={id + "-min"}
        className={classes.numberField}
        InputProps={{ style: { fontFamily: "BoschSans" } }}
        InputLabelProps={{ style: { fontFamily: "BoschSans" } }}
        type="number"
        label="Min"
        name="min"
        error={touched.min && Boolean(errors.min)}
        onChange={event => {
          handleChange(event);
          submitForm();
        }}
        onBlur={event => {
          handleBlur(event);
          submitForm();
        }}
        value={values.min}
      />
    </form>
    <form onSubmit={handleSubmit} className={classes.form}>
      <TextField
        id={id + "-max"}
        className={classes.numberField}
        InputProps={{ style: { fontFamily: "BoschSans" } }}
        InputLabelProps={{ style: { fontFamily: "BoschSans" } }}
        type="number"
        label="Max"
        name="max"
        error={touched.max && Boolean(errors.max)}
        onChange={event => {
          handleChange(event);
          submitForm();
        }}
        onBlur={event => {
          handleBlur(event);
          submitForm();
        }}
        value={values.max}
      />
    </form>
    <p className={classes.errorMessage}>
      <ErrorMessage name="min" />
    </p>
    <p className={classes.errorMessage}>
      <ErrorMessage name="max" />
    </p>
  </div>
);

export function collapsibleMultiRange(
  id,
  value,
  title,
  open,
  step,
  min,
  max,
  classes,
  handleOpen,
  handleSliderChange,
  validationSchema,
  onSubmit
) {
  return (
    <div key={id + "-div-container"}>
      <ListItem id={id} button onClick={handleOpen} key={id}>
        <ListItemText
          primary={title}
          classes={{ primary: classes.listItemText }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse key={id + "-collapse"} in={open} timeout="auto">
        <div className={classes.nested}>
          <Range
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={handleSliderChange}
          />
          <Formik
            initialValues={{ min: value[0], max: value[1] }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {minMaxForms(classes,id)}
          </Formik>
        </div>
      </Collapse>
    </div>
  );
}

export function collapsibleDatePicker(
  id,
  startDate,
  endDate,
  title,
  open,
  classes,
  handleOpen,
  handleStartDateChange,
  handleEndDateChange,
  handleAcceptDate
) {
  return (
    <div key={id + "-div-container"}>
      <ListItem id={id} button onClick={handleOpen} key={id}>
        <ListItemText
          primary={title}
          classes={{ primary: classes.listItemText }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse key={id + "-collapse"} in={open} timeout="auto">
        <div className={classes.nested}>
          <KeyboardDatePicker
            InputProps={{ style: { fontFamily: "BoschSans" } }}
            InputLabelProps={{ style: { fontFamily: "BoschSans" } }}
            margin="normal"
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            onAccept={handleAcceptDate}
          />
          <KeyboardDatePicker
            InputProps={{ style: { fontFamily: "BoschSans" } }}
            InputLabelProps={{ style: { fontFamily: "BoschSans" } }}
            margin="normal"
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            onAccept={handleAcceptDate}
          />
        </div>
      </Collapse>
    </div>
  );
}

export function collapsibleTimePicker(
         id,
         startTime,
         endTime,
         title,
         open,
         classes,
         handleOpen,
         handleStartTimeChange,
         handleEndTimeChange,
         handleAcceptTime
       ) {
         return (
           <div key={id + "-div-container"}>
             <ListItem id={id} button onClick={handleOpen} key={id}>
               <ListItemText
                 primary={title}
                 classes={{ primary: classes.listItemText }}
               />
               {open ? <ExpandLess /> : <ExpandMore />}
             </ListItem>
             <Collapse key={id + "-collapse"} in={open} timeout="auto">
               <div className={classes.nested}>
                 <KeyboardTimePicker
                   ampm={false}
                   margin="normal"
                   label="Start Time"
                   value={startTime}
                   onChange={handleStartTimeChange}
                   InputProps={{ style: { fontFamily: "BoschSans" } }}
                   InputLabelProps={{ style: { fontFamily: "BoschSans" } }}
                   onAccept={handleAcceptTime}
                 />
                 <KeyboardTimePicker
                   InputProps={{ style: { fontFamily: "BoschSans" } }}
                   InputLabelProps={{ style: { fontFamily: "BoschSans" } }}
                   ampm={false}
                   margin="normal"
                   label="End Time"
                   value={endTime}
                   onChange={handleEndTimeChange}
                   onAccept={handleAcceptTime}
                 />
               </div>
             </Collapse>
           </div>
         );
       }



export function collapsibleDimensionFilter(
  id,
  value,
  title,
  open,
  classes,
  handleOpen,
  handleSliderChangeHeight,
  handleSliderChangeWidth,
  handleSliderChangeLength,
  validationSchema,
  onSubmitHandlers
) {
  return (
    <div key={id + "-div-container"}>
      <ListItem id={id} button onClick={handleOpen} key={id}>
        <ListItemText
          primary={title}
          classes={{ primary: classes.listItemText }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse key={id + "-collapse"} in={open} timeout="auto">
        <List className={classes.nested}>
          <ListItem key={"list-item-length"} className={classes.container}>
            <ListItemText
              primary="Length"
              classes={{ primary: classes.listItemText }}
            />
            <Range
              min={0}
              max={700}
              step={10}
              value={value["length"]}
              onChange={handleSliderChangeLength}
            />
            <Formik
              initialValues={{
                min: value["length"][0],
                max: value["length"][1]
              }}
              onSubmit={onSubmitHandlers["length"]}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {minMaxForms(classes, "length")}
            </Formik>
          </ListItem>
          <Divider />
          <ListItem key={"list-item-width"} className={classes.container}>
            <ListItemText
              primary="Width"
              classes={{ primary: classes.listItemText }}
            />
            <Range
              min={0}
              max={700}
              step={10}
              value={value["width"]}
              onChange={handleSliderChangeWidth}
            />
            <Formik
              initialValues={{
                min: value["width"][0],
                max: value["width"][1]
              }}
              onSubmit={onSubmitHandlers["width"]}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {minMaxForms(classes, "width")}
            </Formik>
          </ListItem>
          <Divider />
          <ListItem key={"list-item-height"} className={classes.container}>
            <ListItemText
              primary="Height"
              classes={{ primary: classes.listItemText }}
            />
            <Range
              min={0}
              max={700}
              step={10}
              value={value["height"]}
              onChange={handleSliderChangeHeight}
            />
            <Formik
              initialValues={{
                min: value["height"][0],
                max: value["height"][1]
              }}
              onSubmit={onSubmitHandlers["height"]}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {minMaxForms(classes, "height")}
            </Formik>
          </ListItem>
          <Divider />
        </List>
      </Collapse>
    </div>
  );
}

export function collapsibleIdTextboxes(
  id,
  values,
  title,
  open,
  classes,
  handleOpen,
  handleChangeArray
) {
  return (
    <div key={id + "-div-container"}>
      <ListItem key={"list-item-" + id} button onClick={handleOpen}>
        <ListItemText
          primary={title}
          classes={{ primary: classes.listItemText }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse key={id + "-collapse"} in={open} timeout="auto" unmountOnExit>
        <List className={classes.nested}>
          <ListItem id={"RecipeIdItem"} className={classes.container}>
            <TextField
              id={"RecipeId"}
              label="RecipeId"
              className={classes.textField}
              InputProps={{ style: { fontFamily: "BoschSans" } }}
              InputLabelProps={{ style: { fontFamily: "BoschSans" } }}
              value={values['RecipeId']}
              onChange={handleChangeArray("RecipeId")}
            />
          </ListItem>
          <ListItem key={"StepIdItem"} className={classes.container}>
            <TextField
              id={"StepId"}
              label="StepId"
              className={classes.textField}
              InputProps={{ style: { fontFamily: "BoschSans" } }}
              InputLabelProps={{ style: { fontFamily: "BoschSans" } }}
              value={values["StepId"]}
              onChange={handleChangeArray("StepId")}
            />
          </ListItem>
          <ListItem key={"LightIdItem"} className={classes.container}>
            <TextField
              id={"LightId"}
              label="LightId"
              className={classes.textField}
              InputProps={{ style: { fontFamily: "BoschSans" } }}
              InputLabelProps={{ style: { fontFamily: "BoschSans" } }}
              value={values["LightId"]}
              onChange={handleChangeArray("LightId")}
            />
          </ListItem>
          <ListItem key={"PhotoSessionIdItem"} className={classes.container}>
            <TextField
              id={"PhotoSessionId"}
              label="PhotoSessionId"
              className={classes.textField}
              InputProps={{ style: { fontFamily: "BoschSans" } }}
              InputLabelProps={{ style: { fontFamily: "BoschSans" } }}
              value={values["PhotoSessionId"]}
              onChange={handleChangeArray("PhotoSessionId")}
            />
          </ListItem>
          <ListItem key={"CameraNumberItem"} className={classes.container}>
            <TextField
              id={"CameraNumber"}
              label="CameraNumber"
              className={classes.textField}
              InputProps={{ style: { fontFamily: "BoschSans" } }}
              InputLabelProps={{ style: { fontFamily: "BoschSans" } }}
              value={values["CameraNumber"]}
              onChange={handleChangeArray("CameraNumber")}
            />
          </ListItem>
        </List>
      </Collapse>
    </div>
  );
}
