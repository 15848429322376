import { UPDATE_TAG_COLOR_MAPPING } from "./tagActionTypes";
import { TAG_RGB_COLOR_LIST } from "./tagColorList";

const initial = {
    tagColorMapping: {}
}

function tagReducer(state = initial, action) {
    switch (action.type) {
        case UPDATE_TAG_COLOR_MAPPING:
            let updatedColorMap = state.tagColorMapping;
            action.tags.forEach(tag => {
                if(! updatedColorMap[tag]) {
                    var newIndex = Object.keys(updatedColorMap).length
                    var color =
                      TAG_RGB_COLOR_LIST[newIndex % TAG_RGB_COLOR_LIST.length];
                    updatedColorMap = {
                      ...updatedColorMap,
                      [tag]: color};
                }
            })
            return { ...state, tagColorMapping: updatedColorMap };
        default:
            return state;
    }
}

export default tagReducer;