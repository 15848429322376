import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setFilterSearchString } from "../Filterbar/filterActions";
import { getImageMetadata } from "../Filterbar/imageActions";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { SEARCH_SERVICE_URL } from "../../config";

const styles = theme => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.5),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.6)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 400
    },
    searchField: {
      flexGrow: 1,
      fontFamily: "BoschSansBold"
    }
  }
});

const customStyles = {
  control: provided => ({
    ...provided,
    position: "relative",
    backgroundColor: "#fff",
    marginLeft: 0,
    width: "300px"
  }),
  option: provided => ({
    ...provided,
    color: "black"
  })

};

function AutoCompletionSearch(props) {
  const {
    auth,
    setSearchString,
    getImageMetadata
  } = props;

  const onChange = selection => {
    setSearchString(selection? selection.value : "");
    getImageMetadata();
  };

  const promiseOptions = inputValue => {
    const accessToken = auth.aadResponse.jwtAccessToken;
    return new Promise(resolve => {
      resolve(
        axios({
          method: "get",
          url: SEARCH_SERVICE_URL + "auto-completion?searchInput=" + inputValue,
          headers: { Authorization: `Bearer ${accessToken}` }
        }).then(response =>
          response.data.map(suggestion => ({
            label: suggestion,
            value: suggestion
          }))
        )
      );
    });
  };

  return (
    <div style={{ paddingRight: "50px" }}>
      <AsyncSelect
        placeholder="Object-ID..."
        cacheOptions
        isClearable={true}
        loadOptions={promiseOptions}
        onChange={onChange}
        styles={customStyles}
      />
    </div>
  );
}

function TextSearch(props) {
  const { classes, filter, setSearchString, getImageMetadata } = props;

  const onQueryInput = event => {
    setSearchString(event.target.value);
    getImageMetadata();
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        onChange={onQueryInput}
        value={filter.searchString}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  filter: state.filter,
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  setSearchString: bindActionCreators(setFilterSearchString, dispatch),
  getImageMetadata: bindActionCreators(getImageMetadata, dispatch)
});

TextSearch.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AutoCompletionSearch));
