export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const OPEN_DOWNLOAD_ALERT = "OPEN_DOWNLOAD_ALERT";
export const DOWNLOAD_IN_PROGRESS = "DOWNLOAD_IN_PROGRESS";
export const DOWNLOAD_METADATA = "DOWNLOAD_METADATA";

export function downloadFiles() {
  return {
    type: DOWNLOAD_FILE
  };
}

export function openDownloadAlert(open) {
  return {
    type: OPEN_DOWNLOAD_ALERT,
    open
  }
}

export function setDownloadInProgress(inProgress) {
  return {
    type: DOWNLOAD_IN_PROGRESS,
    inProgress
  }
}

export function downloadMetadata() {
  return {
    type: DOWNLOAD_METADATA
  }
}