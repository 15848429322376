import {
  DOWNLOAD_FILE,
  openDownloadAlert,
  setDownloadInProgress,
  DOWNLOAD_METADATA
} from "./downloadAction";
import { takeLatest, call, cancelled, select, put } from "redux-saga/effects";
import axios from "axios";
import {
  ZIP_SERVICE_URL,
  ZIP_FUNCTION_KEY,
  SEARCH_SERVICE_URL,
  CSV_DELIMITER,
  CSV_ARRAY_DELIMITER
} from "../../config";
import { delay } from "q";
import {
  computeSelectedImages,
  buildRequestBody
} from "../Filterbar/imageSagas";

import Papa from "papaparse";
import { format } from "date-fns";

function* downloadFilesSaga(action) {
  const sasToken = yield select(
    state => state.auth.sasToken
  );
  const jwtAccessToken = yield select(
    state => state.auth.aadResponse.jwtAccessToken
  );
  const metadata = yield select(state => state.image.metadata);
  const groups = yield select(state => state.image.selectedImages);
  const selectedGroups = yield select(state => state.image.selectedGroups);
  const filter = yield select(state => state.filter);

  //Make a search service call with only groupId as filter to retrieve all images in a group
  yield put(setDownloadInProgress(true));
  yield put(openDownloadAlert(true));

  let selectedGroupsArray = Object.keys(selectedGroups).filter(
    groupId => selectedGroups[groupId]
  );
  let selection = [];
  Object.keys(groups).forEach(groupId => {
    selection = selection.concat(groups[groupId].map(image => image.uri));
  });

  const finalSelection = yield call(
    computeSelectedImages,
    selectedGroupsArray,
    selection,
    filter,
    jwtAccessToken
  );

  let csvContentList = new Map();
  var csvContent;
  for (var groupId of selectedGroupsArray) {
    csvContent = yield* getMetadataAsCSV(
      [groupId],
      filter,
      jwtAccessToken,
      groups,
      metadata
    );
    csvContentList.set(groupId, csvContent);
  }
  if (selectedGroupsArray.length === 0) {
    csvContent = yield* getMetadataAsCSV(
      [],
      filter,
      jwtAccessToken,
      groups,
      metadata
    );
    csvContentList.set(Object.keys(groups)[0], csvContent);
  }

  const requestBody = {
    imageUriList: [...finalSelection],
    sasToken: sasToken,
    csvContent: Array.from(csvContentList.entries())
  };

  try {
    const {
      status,
      data: { statusQueryGetUri, terminatePostUri }
    } = yield call(axios, {
      method: "post",
      url: ZIP_SERVICE_URL + "?code=" + ZIP_FUNCTION_KEY,
      data: requestBody
    });
    if (status === 202) {
      //Notify user that download request has been send

      try {
        let finished = false;

        while (!finished) {
          yield delay(5000);
          let response = yield axios({
            method: "get",
            url: statusQueryGetUri
          });
          if (response.status === 200 || response.status === 202) {
            if (response.data && response.data.runtimeStatus === "Completed") {
              for (let index in response.data.output) {
                window.open(response.data.output[index], "_blank");
              }
              finished = true;
            }
          }
        }
      } finally {
        //cancel zipping when saga is cancelled
        if (yield cancelled()) {
          call(axios, {
            method: "post",
            url: terminatePostUri
          });
        }
      }
    }
  } catch (error) {
  } finally {
    yield put(setDownloadInProgress(false));
  }
}

function* downloadMetadataSaga(action) {
  const jwtAccessToken = yield select(
    state => state.auth.aadResponse.jwtAccessToken
  );
  const groups = yield select(state => state.image.selectedImages);
  const metadata = yield select(state => state.image.metadata);
  const selectedGroups = yield select(state => state.image.selectedGroups);
  const filter = yield select(state => state.filter);

  //Make a search service call with only groupId as filter to retrieve all images in a group
  yield put(setDownloadInProgress(true));
  yield put(openDownloadAlert(true));

  let selectedGroupsArray = Object.keys(selectedGroups).filter(
    groupId => selectedGroups[groupId]
  );

  var csvContent = yield* getMetadataAsCSV(
    selectedGroupsArray,
    filter,
    jwtAccessToken,
    groups,
    metadata
  );

  var blob = new Blob([csvContent], { type: "text/csv" });

  var url = window.URL.createObjectURL(blob);

  var link = document.createElement("a");
  link.setAttribute("href", url);

  const fileName = format(new Date(), "yyyy-MM-dd") + "_metadata.csv";

  link.setAttribute("download", fileName);
  document.body.appendChild(link); // Required for FF

  link.click();

  window.URL.revokeObjectURL(url);

  yield put(setDownloadInProgress(false));
}

function* getMetadataAsCSV(
  selectedGroupsArray,
  filter,
  accessToken,
  groups,
  metadata
) {
  let imageMetadata = yield call(
    getImageDataFromGroupId,
    selectedGroupsArray,
    filter,
    accessToken
  );

  Object.keys(groups).forEach(groupId => {
    groups[groupId].forEach(selectedImage => {
      let imgData = metadata[groupId].find(
        imageData => imageData.uri === selectedImage.uri
      );
      imageMetadata = imgData ? imageMetadata.add(imgData) : imageMetadata;
    });
  });

  var dataArray = Array.from(imageMetadata);
  dataArray = dataArray.map(image => {
    let tagString = "[";
    if (image["tags"]) {
      image["tags"].forEach(
        tag => (tagString = tagString + tag.name + CSV_ARRAY_DELIMITER)
      );
      tagString = tagString.substring(0, tagString.length - 1) + "]";
      if (tagString.length === 1) {
        tagString = "";
      }
    } else {
      tagString = "";
    }

    return { ...image, tags: tagString };
  });

  var csvContent = Papa.unparse(dataArray, { delimiter: CSV_DELIMITER });
  return csvContent;
}

export function* getImageDataFromGroupId(groupIdList, filter, accessToken) {
  let imageData = new Set();
  let requestBody = buildRequestBody(filter, false);
  var i;
  try {
    for (i = 0; i < groupIdList.length; i++) {
      requestBody["objectNameEquals"] = groupIdList[i];
      const { data } = yield call(axios, {
        method: "post",
        url: SEARCH_SERVICE_URL + "search",
        headers: { Authorization: `Bearer ${accessToken}` },
        data: requestBody
      });
      if (data) {
        data.forEach(image => imageData.add(image));
      }
    }
  } catch (error) {}
  return imageData;
}

export const downloadSagas = [
  takeLatest(DOWNLOAD_FILE, downloadFilesSaga),
  takeLatest(DOWNLOAD_METADATA, downloadMetadataSaga)
];
