import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Gallery from "./Gallery";
import Filterbar from "../Filterbar";
import { Grid } from "@material-ui/core";
import AddTagDialog from "./AddTagDialog";
import {
  getTags,
  addTag,
  removeTag,
  setFilterSearchString
} from "../Filterbar/filterActions";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import {
  toogleSingleImageSelection,
  toogleImageGroupSelection,
  setImageGroupSelection,
  setImageSelection,
  clearImageSelection,
  getImageMetadata,
  setTagAlertOpen
} from "../Filterbar/imageActions";
import boschSupergrafic from "../../resource/Bosch-Supergraphic.svg";
import ActionMenu from "./ActionMenu";
import PortalAppBar from "./PortalAppBar";
import RemoveTagDialog from "./RemoveTagDialog";
import {
  downloadFiles,
  openDownloadAlert,
  downloadMetadata
} from "../Download/downloadAction";
import { DownloadSnackbar } from "../Download/downloadAlert";
import { ImageCounter, ObjectCounter } from "./Counter";
import { OperationAlert } from "./OperationAlert";

import Footer from "../Footer";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundImage: "url(" + boschSupergrafic + ")",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  lougoutIcon: {
    width: "35px",
    height: "35px"
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1,
    fontFamily: "BoschSansBold"
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(0)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100vh",
    overflow: "auto"
  },
  chartContainer: {
    marginLeft: -22
  },
  tableContainer: {
    height: 320
  },
  h5: {
    marginBottom: theme.spacing(2)
  },
  actionButton: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
    top: theme.spacing(10),
    left: theme.spacing(35)
  },
  objectCounter: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
    top: theme.spacing(10),
    right: theme.spacing(3)
  },
  counterContent: {
    paddingTop: "8px",
    "&:last-child": {
      paddingBottom: "8px"
    },
    tableRow: {
      borderBottom: "none"
    }
  }
});

class Dashboard extends React.Component {
  state = {
    open: true,
    openDialog: {
      tag: false,
      removeTag: false
    },
    anchorEl: null
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.props.getImageMetadata();
    });
    setTimeout(() => {
      this.props.getImageMetadata();
    }, 1500);
    
  }
  componentWillUnmount() {
    this.unlisten();
  }

  handleMenu = event => {
    event.persist();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleDialogOpen = dialog => () => {
    this.setState({ openDialog: { ...this.state.openDialog, [dialog]: true } });
  };

  handleDialogClose = dialog => () => {
    this.setState({
      openDialog: { ...this.state.openDialog, [dialog]: false }
    });
  };

  selectAllGroups = select => {
    const { representativeData, setImageGroupSelection } = this.props;
    const data = representativeData || [];
    data.map(representative =>
      setImageGroupSelection(representative.ID, select)
    );
  };

  selectAllImagesInView = groupId => select => {
    const { metadata, setImageSelection } = this.props;
    const images = metadata[groupId] || [];
    images.map(image => setImageSelection(image, select));
  };

  toogleGroupSelection = groupId => () => {
    this.props.toogleImageGroupSelection(groupId);
  };

  isEveryGroupSelected = () => {
    const { representativeData, selectedGroups } = this.props;
    let isEverythingSelected = true;
    const data = representativeData || [];
    data.forEach(
      element =>
        (isEverythingSelected =
          isEverythingSelected && selectedGroups[element.ID])
    );
    return isEverythingSelected && data.length > 0;
  };

  isEverythingInGroupSelected = groupId => {
    const { metadata, selectedImages } = this.props;
    let isEverythingSelected = true;
    const data = metadata[groupId] || [];
    const selection = selectedImages[groupId] || [];
    data.forEach(element => {
      const wantedImage = selection.filter(
        selectedImgage => selectedImgage.uri === element.uri
      );

      isEverythingSelected = isEverythingSelected && wantedImage.length > 0;
    });
    return isEverythingSelected && data.length > 0;
  };

  isAnythingSelected = () => {
    const { selectedImages, selectedGroups } = this.props;
    let selected = false;
    Object.keys(selectedImages).forEach(element => {
      selected =
        selected ||
        (selectedImages[element] && selectedImages[element].length > 0);
    });
    Object.keys(selectedGroups).forEach(key => {
      selected = selected || selectedGroups[key];
    });
    return selected;
  };

  handleNewTag = values => {
    if (values.newTag !== "") {
      this.props.addTag(values.newTag);
    }
  };

  handleRemoveTag = values => {
    if (values.tag !== "") {
      this.props.removeTag(values.tag);
    }
  };

  getOnlyApplicableTags = () => {
    const { selectedImages } = this.props;
    let tagSet = new Set();
    for (let group in selectedImages) {
      selectedImages[group].forEach(img => {
        img.tags.forEach(tag => tagSet.add(tag));
      });
    }
    return [...tagSet];
  };

  totalImageCount = () => {
    const { metadata } = this.state;
    let totalCount = 0;
    totalCount = Object.keys(metadata).reduce(
      (accumulator, objectKey) => accumulator + metadata[objectKey].length,
      0
    );
    return totalCount;
  };

  onRouteChange = objectId => {
    this.props.clearImageSelection();
    //this.props.setSearchString(objectId);
    //this.props.getImageMetadata();
  };

  render() {
    const {
      classes,
      metadata,
      representativeData,
      logoutFunction,
      toogleSingleImageSelection,
      toogleImageGroupSelection,
      selectedImages,
      selectedGroups,
      downloadAlertOpen,
      openDownloadAlert,
      downloadFiles,
      downloadMetadata,
      downloadInProgress,
      clearImageSelection,
      tags,
      getImageMetadata,
      tagAlertOpen,
      tagAlertMessage,
      setTagAlertOpen,
      isDataFetching,
      colorMappings
    } = this.props;
    const { anchorEl, open } = this.state;
    const openMenu = Boolean(anchorEl);
    return (
        <div className={classes.root}>
            <CssBaseline />
            <PortalAppBar
                classes={classes}
                openDrawer={open}
                anchorEl={anchorEl}
                openMenu={openMenu}
                handleDrawerOpen={this.handleDrawerOpen}
                handleMenu={this.handleMenu}
                handleMenuClose={this.handleMenuClose}
                logoutFunction={logoutFunction}
                selectedImages={selectedImages}
                onRouteChange={this.onRouteChange}
            />

            <Drawer
                variant='permanent'
                classes={{
                    paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                }}
                open={this.state.open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={this.handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <Filterbar />
                <Divider />
            </Drawer>
            <main className={classes.content}>
                <Grid container direction='column'>
                    <div className={classes.appBarSpacer} />
                    <Grid item>
                        <Switch>
                            <Route
                                path='/:objectId'
                                onEnter
                                render={({ match }) => (
                                    <div>
                                        <ImageCounter classes={classes} data={metadata || []} />
                                        <Gallery
                                            data={metadata[match.params.objectId] || []}
                                            colorMappings={colorMappings}
                                            title={match.params.objectId}
                                            toogleImageSelection={toogleSingleImageSelection}
                                            toogleGroupSelection={toogleImageGroupSelection}
                                            selectedImages={selectedImages}
                                            selectedGroups={selectedGroups}
                                            onRouteChange={this.onRouteChange}
                                            getImageMetadata={getImageMetadata}
                                            isDataFetching={isDataFetching}
                                        />
                                    </div>
                                )}
                            />
                            <Route
                                render={() => (
                                    <div>
                                        <ObjectCounter classes={classes} data={representativeData || []} />
                                        <Gallery
                                            data={representativeData || []}
                                            colorMappings={colorMappings}
                                            title='Overview'
                                            toogleImageSelection={toogleSingleImageSelection}
                                            toogleGroupSelection={toogleImageGroupSelection}
                                            selectedImages={selectedImages}
                                            selectedGroups={selectedGroups}
                                            onRouteChange={this.onRouteChange}
                                            getImageMetadata={getImageMetadata}
                                            isDataFetching={isDataFetching}
                                        />
                                        <Footer />
                                    </div>
                                )}
                            />
                        </Switch>
                    </Grid>
                </Grid>
                <Switch>
                    <Route
                        path='/:objectId'
                        render={({ match }) => {
                            return (
                                <ActionMenu
                                    selectAllHandler={this.selectAllImagesInView(match.params.objectId)}
                                    addTagHandler={this.handleDialogOpen("tag")}
                                    removeTagHandler={this.handleDialogOpen("removeTag")}
                                    isEverythingSelected={this.isEverythingInGroupSelected(match.params.objectId)}
                                    isAnythingSelected={this.isAnythingSelected()}
                                    downloadHandler={downloadFiles}
                                    metadataDownloadHandler={downloadMetadata}
                                    downloadInProgress={downloadInProgress}
                                    clearAllHandler={clearImageSelection}
                                />
                            );
                        }}
                    />
                    <Route
                        render={() => {
                            return (
                                <ActionMenu
                                    selectAllHandler={this.selectAllGroups}
                                    addTagHandler={this.handleDialogOpen("tag")}
                                    removeTagHandler={this.handleDialogOpen("removeTag")}
                                    isEverythingSelected={this.isEveryGroupSelected()}
                                    isAnythingSelected={this.isAnythingSelected()}
                                    downloadHandler={downloadFiles}
                                    metadataDownloadHandler={downloadMetadata}
                                    downloadInProgress={downloadInProgress}
                                    clearAllHandler={clearImageSelection}
                                />
                            );
                        }}
                    />
                </Switch>
                <div className={classes.appBarSpacer} />
            </main>
            <AddTagDialog
                onClose={this.handleDialogClose("tag")}
                open={this.state.openDialog.tag}
                onUpdate={this.handleNewTag}
            />
            <RemoveTagDialog
                onClose={this.handleDialogClose("removeTag")}
                open={this.state.openDialog.removeTag}
                onUpdate={this.handleRemoveTag}
                getTags={() => Object.keys(tags)}
            />
            <DownloadSnackbar open={downloadAlertOpen} handleClose={() => openDownloadAlert(false)} />
            <OperationAlert open={tagAlertOpen} message={tagAlertMessage} handleClose={() => setTagAlertOpen(false)} />
            <Footer />
        </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getTags: bindActionCreators(getTags, dispatch),
  toogleSingleImageSelection: bindActionCreators(
    toogleSingleImageSelection,
    dispatch
  ),
  toogleImageGroupSelection: bindActionCreators(
    toogleImageGroupSelection,
    dispatch
  ),
  addTag: bindActionCreators(addTag, dispatch),
  removeTag: bindActionCreators(removeTag, dispatch),
  downloadFiles: bindActionCreators(downloadFiles, dispatch),
  downloadMetadata: bindActionCreators(downloadMetadata, dispatch),
  openDownloadAlert: bindActionCreators(openDownloadAlert, dispatch),
  setImageGroupSelection: bindActionCreators(setImageGroupSelection, dispatch),
  setImageSelection: bindActionCreators(setImageSelection, dispatch),
  clearImageSelection: bindActionCreators(clearImageSelection, dispatch),
  setSearchString: bindActionCreators(setFilterSearchString, dispatch),
  getImageMetadata: bindActionCreators(getImageMetadata, dispatch),
  setTagAlertOpen: bindActionCreators(setTagAlertOpen, dispatch)
});

const mapStateToProps = state => ({
  metadata: state.image.metadata,
  representativeData: state.image.representativeMetadata,
  selectedImages: state.image.selectedImages,
  selectedGroups: state.image.selectedGroups,
  tags: state.filter.tags,
  downloadAlertOpen: state.download.openDownloadAlert,
  downloadInProgress: state.download.downloadInProgress,
  tagAlertOpen: state.image.tagAlertOpen,
  tagAlertMessage: state.image.tagAlertMessage,
  isDataFetching: state.image.isDataFetching,
  colorMappings: state.tag.tagColorMapping
});

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Dashboard)));
