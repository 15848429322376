import { SET_IMAGE_METADATA, SET_IMAGE_DATA, GET_IMAGE_METADATA, GET_IMAGE_DATA, GET_PREVIEW_IMAGE_DATA, SET_PREVIEW_IMAGE_DATA, ADD_PREVIEW_IMAGE_DATA, SET_REPRESENTATIVE_METADATA, SET_IMAGE_SELECTION, TOOGLE_SINGLE_IMAGE_SELECTION, TOOGLE_GROUP_IMAGE_SELECTION, SET_IMAGE_GROUP_SELECTION, CLEAR_IMAGE_SELECTION, SET_TAG_ALERT_OPEN, SET_TAG_ALERT_MESSAGE, SET_DATA_FETCHING } from "./actionTypes";


export function setImageMetadata(metadata) {
    return {
        type: SET_IMAGE_METADATA,
        metadata
    }
}

export function setRepresentativeMetadata(metadata) {
    return {
        type: SET_REPRESENTATIVE_METADATA,
        metadata
    }
}

export function setImageData(data) {
    return {
        type: SET_IMAGE_DATA,
        data
    }
}

export function getImageMetadata() {
         return {
           type: GET_IMAGE_METADATA
         };
       }

export function getImageData(uri) {
    return {
        type: GET_IMAGE_DATA,
        uri
    }
}

export function getPreviewImageData(uri, width, height) {
    return {
        type: GET_PREVIEW_IMAGE_DATA,
        uri,
        width,
        height
    }
}

export function setPreviewImageData(data) {
    return {
        type: SET_PREVIEW_IMAGE_DATA,
        data
    }
}

export function addPreviewImageData(data) {
    return {
        type: ADD_PREVIEW_IMAGE_DATA,
        data
    }
}

export function setImageSelection(image, selected) {
  return {
    type: SET_IMAGE_SELECTION,
    image,
    selected
  };
}

export function toogleSingleImageSelection(image, objectId) {
  return {
    type: TOOGLE_SINGLE_IMAGE_SELECTION,
    image,
    objectId
  };
}

export function toogleImageGroupSelection(groupId) {
  return {
    type: TOOGLE_GROUP_IMAGE_SELECTION,
    groupId
  };
}

export function setImageGroupSelection(groupId, selected) {
      return {
        type: SET_IMAGE_GROUP_SELECTION,
        groupId,
        selected
      };
}

export function clearImageSelection() {
    return {
        type: CLEAR_IMAGE_SELECTION
    }
}

export function setTagAlertOpen(open) {
    return {
        type: SET_TAG_ALERT_OPEN,
        open
    }
}

export function setTagAlertMessage(message) {
    return {
        type: SET_TAG_ALERT_MESSAGE,
        message
    }
}

export function setDataFetching(isFetching) {
    return {
        type: SET_DATA_FETCHING,
        isFetching
    }
}