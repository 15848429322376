import { put, call, takeLatest, select, takeEvery } from "redux-saga/effects";
import {
  GET_IMAGE_METADATA,
  GET_TAGS,
  ADD_TAG,
  REMOVE_TAG
} from "./actionTypes";
import axios from "axios";
import {
  SEARCH_SERVICE_URL,
} from "../../config";
import { format } from "date-fns/esm";
import {
  setImageMetadata,
  setRepresentativeMetadata,
  getImageMetadata,
  setTagAlertMessage,
  setTagAlertOpen,
  setDataFetching,
  clearImageSelection
} from "./imageActions";
import { setFilterTags } from "./filterActions";
import { updateTagColorMapping } from "../Tags/tagActions";

function* getImageMetadataSaga() {
  yield put(setDataFetching(true));
  const accessToken = yield select(
    state => state.auth.aadResponse.jwtAccessToken
  );
  const filter = yield select(state => state.filter);
  const inOverview = window.location.pathname === "/";

  let requestBody = buildRequestBody(filter, inOverview);
  if (!inOverview) {
    const objectId = window.location.pathname.split("/")[1];
    requestBody["objectNameEquals"] = objectId;
  }
  try {
    const { data } = yield call(axios, {
      method: "post",
      url: SEARCH_SERVICE_URL + "search",
      headers: { Authorization: `Bearer ${accessToken}` },
      data: requestBody
    });
    //Get SAS Token from redux storage
    const sasToken = yield select(
      state => state.auth.sasToken
    );
    //Append sas token to
    let metadata = {};
    if (data) {
      data.forEach(img => {
        let urlWithSas = img.uri + "?" + sasToken;
        let previewImageUrl = img.thumbnailUri + "?" + sasToken;
        const metadataObject = {
          ...img,
          tags: img.tags? img.tags : [],
          previewUrl: previewImageUrl,
          urlWithSas: urlWithSas
        };
        if (metadata[img.ID]) {
          metadata[img.ID].push(metadataObject);
        } else {
          metadata = {
            ...metadata,
            [img.ID]: [metadataObject]
          };
        }
      });
    }
    if (inOverview) {
      //Compute representative metadata
      let representatives = [];
      Object.keys(metadata).forEach(element => {
        representatives.push(metadata[element][0]);
      });

      //Set representatives data
      yield put(setRepresentativeMetadata(representatives));
    } else {
      //Set Metadata + previewUrl
      yield put(setImageMetadata(metadata));
    }
  } catch (error) {}
  yield put(setDataFetching(false));
}

export function buildRequestBody(filter, distinct) {
  return {
    objectName: filter.searchString,
    minWeight: "" + filter.weight[0],
    maxWeight: "" + filter.weight[1],
    minWidth: filter.dimensions.width[0],
    maxWidth: filter.dimensions.width[1],
    minLength: filter.dimensions.length[0],
    maxLength: filter.dimensions.length[1],
    minHeight: filter.dimensions.height[0],
    maxHeight: filter.dimensions.height[1],
    minElevationAngle: filter.elevationAngle[0],
    maxElevationAngle: filter.elevationAngle[1],
    minSpinAngle: filter.spinAngle[0],
    maxSpinAngle: filter.spinAngle[1],
    minCreationDate: format(filter.startDateTime, "yyyy.MM.dd"),
    maxCreationDate: format(filter.endDateTime, "yyyy.MM.dd"),
    minCreationTime: format(filter.startDateTime, "HH:mm:ss"),
    maxCreationTime: format(filter.endDateTime, "HH:mm:ss"),
    recipeId: filter.recipeId,
    stepId: filter.stepId,
    lightId: filter.lightId,
    photoSessionId: filter.photoSessionId,
    cameraNumber: filter.cameraNumber,
    imageId: filter.imageId,
    tags: Object.keys(filter.tags).filter(tag => filter.tags[tag]),
    isTopLevelRequest: distinct ? true : false
  };
}

function* getTagsSaga(action) {
  const accessToken = yield select(
    state => state.auth.aadResponse.jwtAccessToken
  );
  try {
    const response = yield call(axios, {
      method: "get",
      url: SEARCH_SERVICE_URL + "tags",
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (response.status === 200) {
      yield put(updateTagColorMapping(response.data || []));
      yield put(setFilterTags(response.data || []));
      
    }
  } catch (error) {}
}

function* addTagSaga(action) {
  yield put(setTagAlertMessage("Adding Tag " + action.tag + " to images. This may take a moment."));
  yield put(setTagAlertOpen(true));
  const accessToken = yield select(
    state => state.auth.aadResponse.jwtAccessToken
  );
  const filter = yield select(state => state.filter);
  const groups = yield select(state => state.image.selectedImages);
  const selectedGroups = yield select(state => state.image.selectedGroups);
  let selectedGroupsArray = Object.keys(selectedGroups).filter(
    groupId => selectedGroups[groupId]
  );
  let selection = [];
  Object.keys(groups).forEach(groupId => {
    selection = selection.concat(groups[groupId].map(image => image.uri));
  });

  let requestBody = {};
  if(selectedGroupsArray.length > 0) {
      const filterCriteria = buildRequestBody(filter, false);
      requestBody = {
        tag: action.tag,
        matchCriteria: {
          ...filterCriteria,
          objectIds: selectedGroupsArray
        },
        red: 0,
        green: 0,
        blue: 0,
        alpha: 0
      };

  } else {
      requestBody = {
        imageUris: selection,
        tag: action.tag,
        red: 0,
        green: 0,
        blue: 0,
        alpha: 0
      };

  }

  try {
    yield call(axios, {
      method: "post",
      url: SEARCH_SERVICE_URL + "tags",
      headers: { Authorization: `Bearer ${accessToken}` },
      data: requestBody
    });
    yield call(getTagsSaga);

    yield call(getImageMetadataSaga, getImageMetadata());
    yield put(clearImageSelection());
  } catch (error) {}
  yield put(setTagAlertOpen(false));
}

function* removeTagSaga(action) {
  yield put(setTagAlertMessage("Removing Tag " + action.tag + " from images. This may take a moment."));
  yield put(setTagAlertOpen(true));
  const accessToken = yield select(
    state => state.auth.aadResponse.jwtAccessToken
  );
  const filter = yield select(state => state.filter);
  const groups = yield select(state => state.image.selectedImages);
  const selectedGroups = yield select(state => state.image.selectedGroups);
  let selectedGroupsArray = Object.keys(selectedGroups).filter(
    groupId => selectedGroups[groupId]
  );
  let selection = [];
  Object.keys(groups).forEach(groupId => {
    selection = selection.concat(groups[groupId].map(image => image.uri));
  });

  let requestBody = {};
  if (selectedGroupsArray.length > 0) {
    const filterCriteria = buildRequestBody(filter, false);
    requestBody = {
      tag: action.tag,
      matchCriteria: {
        ...filterCriteria,
        objectIds: selectedGroupsArray
      }
    };
  } else {
    requestBody = {
      imageUris: selection,
      tag: action.tag
    };
  }
  try {
    yield call(axios, {
      method: "delete",
      url: SEARCH_SERVICE_URL + "tags",
      headers: { Authorization: `Bearer ${accessToken}` },
      data: requestBody
    });
    yield call(getTagsSaga);

    yield call(getImageMetadataSaga, getImageMetadata());
    yield put(clearImageSelection());
  } catch (error) {}
  yield put(setTagAlertOpen(false));
}

export function* computeSelectedImages(
  groupIdList,
  selectedImages,
  filter,
  accessToken
) {
  let selection = new Set();
  selectedImages.forEach(imageUri => selection.add(imageUri));
  let requestBody = buildRequestBody(filter, false);
  var i;
  try {
    for (i = 0; i < groupIdList.length; i++) {
      requestBody["objectNameEquals"] = groupIdList[i];
      const { data } = yield call(axios, {
        method: "post",
        url: SEARCH_SERVICE_URL + "search",
        headers: { Authorization: `Bearer ${accessToken}` },
        data: requestBody
      });
      if (data) {
        data.forEach(image => selection.add(image.uri));
      }
    }
  } catch (error) {}
  return selection;
}

export const imageSagas = [
  takeLatest(GET_IMAGE_METADATA, getImageMetadataSaga),
  takeLatest(GET_TAGS, getTagsSaga),
  takeEvery(ADD_TAG, addTagSaga),
  takeEvery(REMOVE_TAG, removeTagSaga)
];
