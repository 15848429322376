import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";

const styles = theme => ({
  input: {
    fontFamily: "BoschSans"
  }
});

function AddTagDialog(props) {

  const { classes, onClose, onUpdate, open } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        initialValues={{ newTag: "" }}
        onSubmit={onUpdate}
        render={renderForm({ onClose, classes })}
      />
    </Dialog>
  );


}

let renderForm = props => ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit
}) => {
  const { onClose, classes } = props;
  return (
    <Form>
      <DialogTitle
        id="edit-form-dialog-title"
        style={{ fontFamily: "BoschSans" }}
      >
        Add Tags to Images
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontFamily: "BoschSans" }}>
          Add a new tag to all selected images:
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="new-tag"
          name="newTag"
          InputProps={{ classes: { input: classes.input } }}
          label="New tag ..."
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          style={{ fontFamily: "BoschSans" }}
        >
          Cancel
        </Button>
        <Button
          onClick={onClose}
          color="primary"
          style={{ fontFamily: "BoschSans" }}
          type="submit"
        >
          Add
        </Button>
      </DialogActions>
    </Form>
  );
};

export default withStyles(styles)(AddTagDialog);