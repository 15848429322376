import React from "react";
import AzureAD, { MsalAuthProviderFactory, LoginType } from "react-aad-msal";
import { AUTH_CONFIG, AUTH_PARAMETERS } from "../config";
import LandingPage from "../components/Landing.js/landingPage";
import App from "../App";
import { reduxStore } from "../store";
import Axios from "axios";

export function Auth() {
  const unauthenticatedFunction = loginFunction => {
    return <LandingPage loginFunction={loginFunction} />;
  };

  const authenticatedFunction = logout => {
    const clearCacheAndLogout = () => {
      Axios.defaults.headers.common["Authorization"] = "";
      logout();
    };

    return <App logoutFunction={clearCacheAndLogout} />;
  };

  const setTokenAsDefaultAfterSuccess = accountInfo => {
  };

  return (
    <AzureAD
      provider={
        new MsalAuthProviderFactory(
          AUTH_CONFIG,
          AUTH_PARAMETERS,
          LoginType.Popup
        )
      }
      unauthenticatedFunction={unauthenticatedFunction}
      authenticatedFunction={authenticatedFunction}
      accountInfoCallback={setTokenAsDefaultAfterSuccess}
      reduxStore={reduxStore}
    />
  );
}
