import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const config = {
    whitelist: []
  };
  const stateSyncMiddleware = createStateSyncMiddleware(config);

  const store = createStore(
    rootReducer,
    compose(
      applyMiddleware(stateSyncMiddleware, sagaMiddleware),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );

  sagaMiddleware.run(rootSaga);
  initStateWithPrevTab(store);
  return store;
}

export const reduxStore = configureStore();
