import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import Img from "react-image";
import NoImage from "../../resource/no_image.jpg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  img: {
    maxWidth: "100%",
    maxHeight: "100%"
  }
}));

export function ImageDialogContent(props) {
  const { onClose, data } = props;

  const classes = useStyles();
  return (
    <div>
      <DialogTitle id={data.metadata_storage_name}>
        {data.metadata_storage_name}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Img
            className={classes.img}
            src={data.urlWithSas}
            loader={<span>Loading ...</span>}
            unloader={<img src={NoImage} alt={data.ID} />}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </div>
  );
}

export function ImageDialog(props) {
  const { onClose, open, data } = props;

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <ImageDialogContent onClose={onClose} data={data} />
    </Dialog>
  );
}

ImageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object
};
