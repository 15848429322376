import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Checkbox from "@material-ui/core/Checkbox";
import ImageSearch from "@material-ui/icons/ImageSearch";
import { InfoDialog } from "./InfoDialog";
import { Route, Switch, Link } from "react-router-dom";
import { Typography, CircularProgress, Chip } from "@material-ui/core";
import NoImage from "../../resource/no_image.jpg";
import Img from "react-image";
import { ImageDialog } from "./ImageDialog";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: "auto",
    height: "auto",
    cellHeight: "auto",
    justifyContent: "center"
  },
  gridItem: {
    width: "fit-content",
    height: "fit-content"
  },
  titleBarTagList: {
    background: "rgba(255,255,255,0)"
  },
  titleBarUnselect: {
    background: "rgba(82,95,107,0)",
    fontFamily: "BoschSans",
    titlewidth: "140px"
  },
  titleBarSelect: {
    background: "rgba(0,142,207,0)",
    fontFamily: "BoschSans"
  },
  titleBarTitle: {
    width: "140px",
    color: "rgba(0,0,0,1)"
  },
  titleBarSubTitle: {
    width: "140px",
    color: "rgba(0,0,0,1)"
  },
  icon: {
    color: "rgba(0, 0, 0, 0.66)"
  },
  img: {
    maxWidth: "100%",
    height: "auto"
  },
  gridListTitle: {
    fontFamily: "BoschSansBold"
  },
  loader: {
    animationDuration: "5000ms",
    width: "100px",
    height: "100px"
  },
  loaderContainer: {
    justifyContent: "center",
    display: "flex",
    width: "150px",
    height: "150px"
  },
  metaTag: {
    textOverflow: "ellipsis",
    display: "block",
    maxWidth: "90px",
    overflow: "hidden"
  },
  chip: props => ({
    backgroundColor: "rgba(" + props.rgb + ",0.7)"
  })
});

function AdaptiveChipRaw(props) {
  const { classes, tag, rgb } = props;
  return (
    <Chip
      style={{ fontFamily: "BoschSans" }}
      id={tag}
      key={tag}
      label={tag}
      rgb={rgb}
      classes={{
        root: classes.chip,
        label: classes.metaTag
      }}
    />
  );
}

const AdaptiveChip = withStyles(styles)(AdaptiveChipRaw);

function MetaTagList(tags, classes, colorMappings) {
  var metaTags = null;
  if (tags) {
    const chips = tags.map(tag => (
      <AdaptiveChip
        rgb={colorMappings[tag.name]}
        key={tag.name}
        classes= {classes}
        tag={tag.name}
      />
    ));
    metaTags = chips.slice(0, 3);
    if(chips.length > 3) {
      const additionalNumberOfTags = chips.length - 3;
      metaTags.push(
        <AdaptiveChip
          rgb={"191,192,194"}
          key={"+ " + additionalNumberOfTags}
          classes={{ label: classes.metaTag, root: classes.chip }}
          tag={"+ " + additionalNumberOfTags}
        />
      );
    }
  }
  return metaTags;
}


function GroupItem(props) {
  const {
    classes,
    data,
    colorMappings,
    openInfoDialog,
    selectedGroups,
    toogleGroupSelection,
    onRouteChange
  } = props;
  const handleCheckboxChange = groupId => event => {
    toogleGroupSelection(groupId);
  };
  let checked = selectedGroups[data.ID] ? true : false;
  return (
    <GridListTile key={data.ID} className={classes.gridItem}>
      <Link to={`/${data.ID}`} onClick={() => onRouteChange(data.ID)}>
        <Img
          src={data.previewUrl}
          loader={<img src={NoImage} alt={data.ID} />}
          unloader={<img src={NoImage} alt={data.ID} />}
          className={classes.img}
        />
      </Link>
      <GridListTileBar
        titlePosition="top"
        className={classes.titleBarTagList}
        actionIcon={MetaTagList(data.tags, classes, colorMappings)}
      />
      <GridListTileBar
        id={data.ID + "-tile-bar"}
        title={data.ID}
        subtitle={<span> by: {data.CameraName} </span>}
        classes={{
          root: checked ? classes.titleBarSelect : classes.titleBarUnselect,
          title: classes.titleBarTitle,
          subtitle: classes.titleBarSubTitle
        }}
        actionIcon={
          <div>
            <IconButton className={classes.icon} onClick={openInfoDialog}>
              <InfoIcon />
            </IconButton>
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange(data.ID)}
              color="default"
              className={classes.icon}
            />
          </div>
        }
      />
    </GridListTile>
  );
}

function ImageItem(props) {
  const {
    classes,
    data,
    colorMappings,
    openInfoDialog,
    groupSelected,
    toogleImageSelection,
    imageSelected,
    openImageDialog
  } = props;
  const handleCheckboxChange = (image, groupId) => event => {
    toogleImageSelection(image, groupId);
  };

  const checked = groupSelected || imageSelected ? true : false;
  const disabled = groupSelected ? true : false;

  const indexOfImgNo = data.metadata_storage_name.indexOf("ImgNo");
  const indexOfFileExtension = data.metadata_storage_name.indexOf("_SpinAngle");

  return (
    <GridListTile key={data.ID} className={classes.gridItem}>
      <Img
        src={data.previewUrl}
        loader={<img src={NoImage} alt={data.ID} />}
        unloader={<img src={NoImage} alt={data.ID} />}
        className={classes.img}
      />
      <GridListTileBar
        titlePosition="top"
        className={classes.titleBarTagList}
        actionIcon={MetaTagList(data.tags, classes, colorMappings)}
      />
      <GridListTileBar
        key={data.ID + "_tilebar"}
        title={data.metadata_storage_name.substring(
          indexOfImgNo,
          indexOfFileExtension
        )}
        classes={{
          root: checked ? classes.titleBarSelect : classes.titleBarUnselect,
          title: classes.titleBarTitle,
          subtitle: classes.titleBarSubTitle
        }}
        subtitle={<span> by: {data.CameraName} </span>}
        actionIcon={
          <div>
            <IconButton className={classes.icon} onClick={openImageDialog}>
              <ImageSearch />
            </IconButton>
            <IconButton className={classes.icon} onClick={openInfoDialog}>
              <InfoIcon />
            </IconButton>
            <Checkbox
              checked={checked}
              disabled={disabled}
              onChange={handleCheckboxChange(
                { uri: data.uri, tags: data.tags, ID: data.ID },
                data.ID
              )}
              color="default"
              className={classes.icon}
            />
          </div>
        }
      />
    </GridListTile>
  );
}

function GalleryContent(props) {
  const {
    data,
    colorMappings,
    classes,
    toogleGroupSelection,
    toogleImageSelection,
    selectedImages,
    selectedGroups,
    onRouteChange
  } = props;

  const [open, setOpen] = useState({});
  const [showImage, setShowImage] = useState({});

  useEffect(() => {
    let openDialogs = {};
    let imageDialogs = {};
    data.forEach(tile => {
      openDialogs = {
        ...openDialogs,
        [tile.metadata_storage_name]: false
      };
      imageDialogs = {
        ...imageDialogs,
        [tile.metadata_storage_name]: false
      };
    });
    setOpen(openDialogs);
    setShowImage(imageDialogs);
  }, [data]);

  const toogleDialogOpen = dialogId => () => {
    setOpen({ ...open, [dialogId]: !open[dialogId] });
  };

  const toogleImageDialog = dialogId => () => {
    setShowImage({ ...showImage, [dialogId]: !showImage[dialogId] });
  };

  return data
    ? data.map(imageData => {
        let infoDialogOpen = open[imageData.metadata_storage_name]
          ? true
          : false;
        let showImageDialog = showImage[imageData.metadata_storage_name]
          ? true
          : false;
        return (
          <div key={imageData.metadata_storage_name + "-container"}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <GroupItem
                    classes={classes}
                    data={imageData}
                    colorMappings={colorMappings}
                    openInfoDialog={toogleDialogOpen(
                      imageData.metadata_storage_name
                    )}
                    openImageDialog={toogleImageDialog(
                      imageData.metadata_storage_name
                    )}
                    toogleGroupSelection={toogleGroupSelection}
                    selectedGroups={selectedGroups}
                    onRouteChange={onRouteChange}
                  />
                )}
              />
              <Route
                path="/:objectId"
                render={match => (
                  <ImageItem
                    classes={classes}
                    data={imageData}
                    colorMappings={colorMappings}
                    openInfoDialog={toogleDialogOpen(
                      imageData.metadata_storage_name
                    )}
                    openImageDialog={toogleImageDialog(
                      imageData.metadata_storage_name
                    )}
                    toogleImageSelection={toogleImageSelection}
                    imageSelected={
                      selectedImages[imageData.ID] &&
                      selectedImages[imageData.ID].some(
                        element => imageData.uri === element.uri
                      )
                    }
                    groupSelected={selectedGroups[imageData.ID] ? true : false}
                  />
                )}
              />
            </Switch>
            <InfoDialog
              open={infoDialogOpen}
              onClose={toogleDialogOpen(imageData.metadata_storage_name)}
              data={imageData}
            />
            <ImageDialog
              open={showImageDialog}
              onClose={toogleImageDialog(imageData.metadata_storage_name)}
              data={imageData}
            />
          </div>
        );
      })
    : null;
}

function Gallery(props) {
  const {
    classes,
    data,
    colorMappings,
    title,
    toogleImageSelection,
    toogleGroupSelection,
    selectedImages,
    selectedGroups,
    onRouteChange,
    isDataFetching
  } = props;

  return (
    <div key={"grid-list-container"} className={classes.root}>
      <GridList cellHeight="auto" cols={2} className={classes.gridList}>
        <GridListTile key="grid-list-title-container" justify="center" cols={2}>
          <Typography
            key="grid-list-title"
            variant="h4"
            align="center"
            gutterBottom
            component="h2"
            className={classes.gridListTitle}
          >
            {title}
          </Typography>
        </GridListTile>
        {isDataFetching ? (
          <GridListTile
            style={{ width: "150px" }}
            className={classes.loaderContainer}
          >
            <CircularProgress
              style={{ width: "80px", height: "80px" }}
              className={classes.loader}
            />
          </GridListTile>
        ) : (
          <GalleryContent
            key={"gallery-content"}
            data={data ? data : []}
            colorMappings={colorMappings}
            classes={classes}
            toogleImageSelection={toogleImageSelection}
            toogleGroupSelection={toogleGroupSelection}
            selectedImages={selectedImages}
            selectedGroups={selectedGroups}
            onRouteChange={onRouteChange}
          />
        )}
      </GridList>
    </div>
  );
}

Gallery.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Gallery);
