import React from 'react';
import './App.css';
import Dashboard from './components/dashboard/Dashboard';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { BrowserRouter } from "react-router-dom";

function App(props) {
  return (
    <BrowserRouter>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Dashboard logoutFunction={props.logoutFunction} />
      </MuiPickersUtilsProvider>
    </BrowserRouter>
  );
}

export default App;
