export const SEARCH_SERVICE_URL = "https://bcs-image-portal.westeurope.cloudapp.azure.com/search-service/";
//export const SEARCH_SERVICE_URL = "http://localhost:8080/";
export const ZIP_SERVICE_URL = "https://zip-download.azurewebsites.net/api/BlobZipDownload";
export const PREVIEW_IMAGE_WIDTH = 300;
export const PREVIEW_IMAGE_HEIGHT = 300;
export const ZIP_FUNCTION_KEY = "V1E9CT9SiUZxold3RDzU5SefctEHpwSeGpauOFShtDsVAzFuo7Hn3Q==";
//const baseUrl = `${window.location.protocol}//${window.location.hostname}`;
const baseUrl = "https://bcs-image-portal.westeurope.cloudapp.azure.com/";

export const AUTH_CONFIG = {
    auth: {
        clientId: "3c1be16c-0fe3-4a3d-973f-39b3a4b1e99b",
        authority: "https://login.microsoftonline.com/0ae51e19-07c8-4e4b-bb6d-648ee58410f4",
        //redirectUri: `${baseUrl}:3000`,
        redirectUri: `${baseUrl}`,
    },
    cache: {
        cacheLocation: "localStorage",
    },
};

export const AUTH_PARAMETERS = {
    scopes: ["api://80a7de84-fa5f-4968-86e3-c0823fb242c1/query_index"],
};

export const CSV_DELIMITER = ";";
export const CSV_ARRAY_DELIMITER = ",";
