import { SET_SAS_TOKEN } from "./authActionTypes";
import jwtDecode from 'jwt-decode';

const initialState = {
  aadResponse: null,
  sasToken: ""
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "AAD_LOGIN_SUCCESS":
      var sasToken = "";
      if(action.payload.jwtAccessToken) {
        const accessToken = jwtDecode(action.payload.jwtAccessToken);
        //RegEx to detect if role is sas token
        const re = new RegExp('^SASToken:')
        accessToken.roles.forEach(role => {
          if(re.test(role)) {
            sasToken = role.replace(re, "");
          }
        });
      }
      return { ...state, sasToken: sasToken ,aadResponse: action.payload };
    case "AAD_LOGOUT_SUCCESS":
      return { ...state, aadResponse: null };
    case SET_SAS_TOKEN:
      return {...state, sasToken: action.sasToken}
    default:
      return state;
  }
};

export default authReducer;
