import React from "../../../node_modules/react";
import {
  ListItem,
  ListItemText,
  Chip,
  withStyles
} from "../../../node_modules/@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import "rc-slider/assets/index.css";

const styles = theme => ({
  chipDefault: props => ({
    width: 200,
    backgroundColor: "rgba(" + props.rgb + ",0.5)",
    "&:hover": {
      backgroundColor: "rgba(" + props.rgb + ",0.7)"
    },
    "&:focus": {
      backgroundColor: "rgba(" + props.rgb + ",0.7)"
    }
  }),
  chipPrimary: props => ({
    width: 200,
    backgroundColor: "rgba(" + props.rgb + ",0.9)",
    "&:hover": {
      backgroundColor: "rgba(" + props.rgb + ",0.7)"
    },
    "&:focus": {
      backgroundColor: "rgba(" + props.rgb + ",0.7)"
    }
  })
});

function AdaptiveChipRaw(props) {
  const { classes, label, values, handleChange, rgb } = props;
  return (
    <Chip
      style={{ fontFamily: "BoschSans" }}
      id={label}
      label={label}
      onClick={handleChange(label)}
      value={label}
      color={values[label] ? "primary" : "default"}
      rgb={rgb}
      classes={{
        root: classes.chipDefault,
        clickableColorPrimary: classes.chipPrimary
      }}
      clickable
    />
  );
}

const AdaptiveChip = withStyles(styles)(AdaptiveChipRaw);

function buildChips(values, handleChange, colorMappings) {
  var checkboxes = [];
  for (var label in values) {
    const rgb = colorMappings[label];
    checkboxes.push(
      <AdaptiveChip
        key={label}
        label={label}
        values={values}
        handleChange={handleChange}
        rgb={rgb}
      ></AdaptiveChip>
    );
  }
  return checkboxes;
}

export function CollapsibleTags(
  id,
  values,
  colorMappings,
  title,
  open,
  classes,
  handleOpen,
  handleChange
) {
  return (
    <div key={id + "-div-container"}>
      <ListItem id={id} button onClick={handleOpen} key={id}>
        <ListItemText
          primary={title}
          classes={{ primary: classes.listItemText }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse key={id + "-collapse"} in={open} timeout="auto">
        <div className={classes.nested}>
          <FormControl component="fieldset">
            <FormGroup>{buildChips(values, handleChange, colorMappings)}</FormGroup>
          </FormControl>
        </div>
      </Collapse>
    </div>
  );
}
