import React from "react";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Menu, MenuItem } from "@material-ui/core";
import AutoSearch from "./MainSearch";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

export default function PortalAppBar(props) {
  const { classes, openDrawer } = props;
  return (
    <AppBar
      position="absolute"
      className={classNames(classes.appBar, openDrawer && classes.appBarShift)}
    >
      <ContextToolbar {...props} />
    </AppBar>
  );
}

function ContextToolbar(props) {
  const {
    classes,
    openDrawer,
    anchorEl,
    openMenu,
    handleDrawerOpen,
    handleMenu,
    handleMenuClose,
    logoutFunction,
    onRouteChange
  } = props;
  return (
    <Toolbar className={classes.toolbar}>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={handleDrawerOpen}
        className={classNames(
          classes.menuButton,
          openDrawer && classes.menuButtonHidden
        )}
      >
        <MenuIcon />
      </IconButton>

      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        className={classes.title}
      >
        <Link component={RouterLink} to="/" color="inherit" onClick={() => onRouteChange("")}>
          BCS Image Portal
        </Link>
      </Typography>

      <AutoSearch />
      <div className={classes.title} />
      <IconButton
        color="inherit"
        onClick={handleMenu}
      >
        <AccountCircle className={classes.lougoutIcon}/>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={openMenu}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={logoutFunction}>Logout</MenuItem>
      </Menu>
    </Toolbar>
  );
}