import {
  SET_IMAGE_METADATA,
  SET_REPRESENTATIVE_METADATA,
  SET_IMAGE_SELECTION,
  TOOGLE_SINGLE_IMAGE_SELECTION,
  TOOGLE_GROUP_IMAGE_SELECTION,
  SET_IMAGE_GROUP_SELECTION,
  CLEAR_IMAGE_SELECTION,
  SET_TAG_ALERT_OPEN,
  SET_TAG_ALERT_MESSAGE,
  SET_DATA_FETCHING
} from "./actionTypes";

const initial = {
  metadata: [],
  representativeMetadata: [],
  selectedImages: {},
  selectedGroups: {},
  tagAlertOpen: false,
  tagAlertMessage: "",
  isDataFetching: false
};

function imageReducer(state = initial, action) {
  switch (action.type) {
    case SET_IMAGE_METADATA:
      return {
        ...state,
        metadata: action.metadata
      };
    case SET_REPRESENTATIVE_METADATA:
      return {
        ...state,
        representativeMetadata: action.metadata
      };
    case SET_IMAGE_SELECTION:

      let selectedImages = state.selectedImages[action.image.ID] || [];
      const filteredImages = selectedImages.filter(
        element => action.image.uri !== element.uri
      );
      if(action.selected) {
        if (filteredImages.length === selectedImages.length) {
          selectedImages.push(action.image);
        }
      } else {
        selectedImages = filteredImages;
      }      
      return {
        ...state,
        selectedImages: {
          ...state.selectedImages,
          [action.image.ID]: selectedImages
        }
      };
    case TOOGLE_SINGLE_IMAGE_SELECTION:
      let selectedGroupMembers = state.selectedImages[action.objectId] || [];
      const filteredGroupMembers = selectedGroupMembers.filter(
        element => action.image.uri !== element.uri
      );
      if (filteredGroupMembers.length === selectedGroupMembers.length) {
        filteredGroupMembers.push(action.image);
      }
      return {
        ...state,
        selectedImages: {
          ...state.selectedImages,
          [action.objectId]: filteredGroupMembers
        }
      };
    case TOOGLE_GROUP_IMAGE_SELECTION:
      return {
        ...state,
        selectedGroups: {
          ...state.selectedGroups,
          [action.groupId]:  state.selectedGroups[action.groupId] ? false : true
        }
      };
      case SET_IMAGE_GROUP_SELECTION:
        return {
          ...state,
          selectedGroups: {
            ...state.selectedGroups,
            [action.groupId]: action.selected
          }
        }
      case CLEAR_IMAGE_SELECTION:
        return {
          ...state,
          selectedImages: initial.selectedImages,
          selectedGroups: initial.selectedGroups
        };
      case SET_TAG_ALERT_OPEN:
        return {
          ...state,
          tagAlertOpen: action.open
        }
      case SET_TAG_ALERT_MESSAGE:
        return {
          ...state,
          tagAlertMessage: action.message
        }
      case SET_DATA_FETCHING:
        return {
          ...state,
          isDataFetching: action.isFetching
        }
    default:
      return state;
  }
}

export default imageReducer;
