export const SET_FILTER_WEIGHT = "SET_FILTER_WEIGHT";
export const RESET_FILTER = "RESET_FILTER";
export const SET_FILTER_WEIGHT_MIN = "SET_FILTER_WEIGHT_MIN";
export const SET_FILTER_WEIGHT_MAX = "SET_FILTER_WEIGHT_MAX";
export const SET_FILTER_START_DATE = "SET_FILTER_START_DATE";
export const SET_FILTER_END_DATE = "SET_FILTER_END_DATE";
export const SET_FILTER_ELEVATION_ANGLE = "SET_FILTER_ELEVATION_ANGLE";
export const SET_FILTER_TAGS = "SET_FILTER_TAGS";
export const TOOGLE_FILTER_TAGS = "TOOGLE_FILTER_TAGS";
export const SET_FILTER_DIMENSIONS_LENGTH = "SET_FILTER_DIMENSIONS_LENGTH";
export const SET_FILTER_DIMENSIONS_WIDTH = "SET_FILTER_DIMENSIONS_WIDTH";
export const SET_FILTER_DIMENSIONS_HEIGHT = "SET_FILTER_DIMENSIONS_HEIGHT";
export const SET_FILTER_SEARCH_STRING = "SET_FILTER_SEARCH_STRING";
export const SET_FILTER_PHOTO_SESSION_ID = "SET_FILTER_PHOTO_SESSION_ID";
export const SET_FILTER_RECIPE_ID = "SET_FILTER_RECIPE_ID";
export const SET_FILTER_LIGHT_ID = "SET_FILTER_LIGHT_ID";
export const SET_FILTER_STEP_ID = "SET_FILTER_STEP_ID";
export const SET_FILTER_SPIN_ANGLE = "SET_FILTER_SPIN_ANGLE";
export const SET_FILTER_SPIN_ANGLE_MIN = "SET_FILTER_SPIN_ANGLE_MIN";
export const SET_FILTER_SPIN_ANGLE_MAX = "SET_FILTER_SPIN_ANGLE_MAX";
export const SET_FILTER_CAMERA_NUMBER = "SET_FILTER_CAMERA_NUMBER";
export const SET_FILTER_IMAGE_ID = "SET_FILTER_IMAGE_ID";
export const GET_IMAGE_METADATA = "GET_IMAGE_METADATA";
export const GET_IMAGE_DATA = "GET_IMAGE_DATA";
export const GET_PREVIEW_IMAGE_DATA = "GET_PREVIEW_IMAGE_DATA";
export const SET_IMAGE_METADATA = "SET_IMAGE_METADATA";
export const SET_IMAGE_DATA = "SET_IMAGE_DATA";
export const SET_PREVIEW_IMAGE_DATA = "SET_PREVIEW_IMAGE_DATA";
export const ADD_PREVIEW_IMAGE_DATA = "ADD_PREVIEW_IMAGE_DATA";
export const SET_REPRESENTATIVE_METADATA = "SET_REPRESENTATIVE_METADATA";
export const SET_IMAGE_SELECTION = "SET_IMAGE_SELECTION";
export const SET_IMAGE_GROUP_SELECTION = "SET_IMAGE_GROUP_SELECTION";
export const CLEAR_IMAGE_SELECTION = "CLEAR_IMAGE_SELECTION";
export const TOOGLE_SINGLE_IMAGE_SELECTION = "TOOGLE_SINGLE_IMAGE_SELECTION";
export const TOOGLE_GROUP_IMAGE_SELECTION = "TOOGLE_GROUP_IMAGE_SELECTION";
export const GET_TAGS = "GET_TAGS";
export const ADD_TAG = "ADD_TAG";
export const REMOVE_TAG = "REMOVE_TAG";
export const SET_TAG_ALERT_MESSAGE = "SET_TAG_ALERT_MESSAGE";
export const SET_TAG_ALERT_OPEN = "SET_TAG_ALERT_OPEN";
export const SET_DATA_FETCHING = "SET_DATA_FETCHING";