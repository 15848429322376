import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@material-ui/core";

const publicMetadataFields = [
  "CameraName",
  "CameraNumber",
  "ID",
  "PostProcessDate",
  "PostProcessTime",
  "ElevationAngle",
  "SpinAngle",
  "LightId",
  "PhotoSessionId",
  "RecipeId",
  "Height",
  "Width",
  "Length",
  "Weight",
  "ExposureTime",
  "metadata_storage_name"
];

export function InfoDialogContent(props) {
  const { onClose, data } = props;

  return (
    <div>
      <DialogTitle id={data.ID}>{data.ID}</DialogTitle>
      <DialogContent >
          <Table>
            <TableBody>
              {Object.keys(data).map(x => {
                if (!publicMetadataFields.includes(x)) {
                  return null;
                }
                return (
                  <TableRow>
                    <TableCell> {x} </TableCell>
                    <TableCell
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        wordBreak: "break-all"
                      }}
                    >
                      {data[x]}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell> Tags </TableCell>
                <TableCell
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    wordBreak: "break-all"
                  }}
                >
                  {data.tags? data.tags.map(tag => tag.name + "  "): ""}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </div>
  );
}

export function InfoDialog(props) {
  const { onClose, open, data } = props;

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <InfoDialogContent onClose={onClose} data={data} />
    </Dialog>
  );
}

InfoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object
};
