import {
    SET_FILTER_TAGS,
    SET_FILTER_ELEVATION_ANGLE,
    SET_FILTER_WEIGHT, 
    SET_FILTER_START_DATE, 
    SET_FILTER_END_DATE, 
    SET_FILTER_DIMENSIONS_LENGTH, 
    SET_FILTER_DIMENSIONS_HEIGHT, 
    SET_FILTER_DIMENSIONS_WIDTH,
    SET_FILTER_SEARCH_STRING,
    SET_FILTER_PHOTO_SESSION_ID,
    SET_FILTER_LIGHT_ID,
    SET_FILTER_RECIPE_ID,
    SET_FILTER_STEP_ID,
    SET_FILTER_SPIN_ANGLE,
    SET_FILTER_IMAGE_ID,
    SET_FILTER_CAMERA_NUMBER,
    SET_FILTER_SPIN_ANGLE_MIN,
    SET_FILTER_SPIN_ANGLE_MAX,
    TOOGLE_FILTER_TAGS,
    GET_TAGS,
    RESET_FILTER,
    ADD_TAG,
    REMOVE_TAG
} from "./actionTypes";


export function setFilterWeight(value) {
    return {
        type: SET_FILTER_WEIGHT,
        value
    }
}

export function setFilterStartDate(value) {
    return {
        type: SET_FILTER_START_DATE,
        value
    }
}

export function setFilterEndDate(value) {
    return {
        type: SET_FILTER_END_DATE,
        value
    }
}

export function setFilterElevationAngle(value) {
    return {
        type: SET_FILTER_ELEVATION_ANGLE,
        value
    }
}

export function setFilterTags(value) {
    return {
        type: SET_FILTER_TAGS,
        value
    }
}

export function getTags() {
    return {
        type: GET_TAGS
    }
}

export function toogleFilterTags(field) {
    return {
        type: TOOGLE_FILTER_TAGS,
        field
    }
}

export function setFilterDimensionsLength(value) {
    return {
        type: SET_FILTER_DIMENSIONS_LENGTH,
        value
    }
}

export function setFilterDimensionsHeight(value) {
    return {
        type: SET_FILTER_DIMENSIONS_HEIGHT,
        value
    }
}

export function setFilterDimensionsWidth(value) {
    return {
        type: SET_FILTER_DIMENSIONS_WIDTH,
        value
    }
}

export function setFilterSearchString(searchString) {
    return {
        type: SET_FILTER_SEARCH_STRING,
        searchString
    }
}

export function setFilterPhotoSessionId(value) {
    return {
        type: SET_FILTER_PHOTO_SESSION_ID,
        value
    }
}

export function setFilterLightId(value) {
    return {
        type: SET_FILTER_LIGHT_ID,
        value
    }
}

export function setFilterRecipeId(value) {
    return {
        type: SET_FILTER_RECIPE_ID,
        value
    }
}

export function setFilterStepId(value) {
    return {
        type: SET_FILTER_STEP_ID,
        value
    }
}

export function setFilterSpinAngle(value) {
    return {
        type: SET_FILTER_SPIN_ANGLE,
        value
    }
}

export function setFilterSpinAngleMax(value) {
    return {
        type: SET_FILTER_SPIN_ANGLE_MAX,
        value
    }
}

export function setFilterSpinAngleMin(value) {
    return {
        type: SET_FILTER_SPIN_ANGLE_MIN,
        value
    }
}

export function setFilterImageId(value) {
    return {
        type: SET_FILTER_IMAGE_ID,
        value
    }
}

export function setFilterCameraNumber(value) {
    return {
        type: SET_FILTER_CAMERA_NUMBER,
        value
    }
}

export function resetFilter() {
    return {
        type: RESET_FILTER
    }
}

export function removeTag(tag) {
    return {
        type: REMOVE_TAG,
        tag
    }
}

export function addTag(tag) {
    return {
        type: ADD_TAG,
        tag
    }
}