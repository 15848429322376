import {startOfDay, endOfDay} from 'date-fns'
import {
  SET_FILTER_WEIGHT,
  SET_FILTER_WEIGHT_MIN,
  SET_FILTER_WEIGHT_MAX,
  SET_FILTER_ELEVATION_ANGLE,
  SET_FILTER_END_DATE,
  SET_FILTER_DIMENSIONS_LENGTH,
  SET_FILTER_DIMENSIONS_WIDTH,
  SET_FILTER_DIMENSIONS_HEIGHT,
  SET_FILTER_START_DATE,
  SET_FILTER_SEARCH_STRING,
  SET_FILTER_IMAGE_ID,
  SET_FILTER_LIGHT_ID,
  SET_FILTER_STEP_ID,
  SET_FILTER_RECIPE_ID,
  SET_FILTER_CAMERA_NUMBER,
  SET_FILTER_PHOTO_SESSION_ID,
  SET_FILTER_SPIN_ANGLE,
  SET_FILTER_SPIN_ANGLE_MIN,
  SET_FILTER_SPIN_ANGLE_MAX,
  TOOGLE_FILTER_TAGS,
  SET_FILTER_TAGS,
  RESET_FILTER,
  ADD_TAG
} from "./actionTypes";

const initial = {
  name: "",
  weight: [0, 250000],
  startDateTime: startOfDay(Date.now()),
  endDateTime: endOfDay(Date.now()),
  // Map instead of Array (tag: string -> isChecked: boolean)
  tags: {},
  elevationAngle: [0, 360],
  dimensions: {
    length: [0, 700],
    height: [0, 700],
    width: [0, 700]
  },
  searchString: "",
  photoSessionId: "",
  recipeId: "",
  stepId: "",
  lightId: "",
  imageId: "",
  cameraNumber: "",
  spinAngle: [0, 360]
};

function filterReducer(state = initial, action) {
  switch (action.type) {
    case SET_FILTER_WEIGHT:
      return {
        ...state,
        weight: action.value
      };
    case SET_FILTER_WEIGHT_MIN:
      return {
        ...state,
        weight: [action.value, state.weight[1]]
      };
    case SET_FILTER_WEIGHT_MAX:
      return {
        ...state,
        weight: [state.weight[0], action.value]
      };
    case SET_FILTER_ELEVATION_ANGLE:
      return {
        ...state,
        elevationAngle: action.value
      };
    case SET_FILTER_START_DATE:
      return {
        ...state,
        startDateTime: action.value,
        endDateTime: (action.value <= state.endDateTime) ? state.endDateTime : action.value
      };
    case SET_FILTER_END_DATE:
      return {
        ...state,
        endDateTime: action.value,
        startDateTime: (action.value >= state.startDateTime) ? state.startDateTime : action.value
      };
    case SET_FILTER_DIMENSIONS_LENGTH:
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          length: action.value
        }
      };
    case SET_FILTER_DIMENSIONS_WIDTH:
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          width: action.value
        }
      };
    case SET_FILTER_DIMENSIONS_HEIGHT:
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          height: action.value
        }
      };
    case SET_FILTER_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString
      };
    case SET_FILTER_IMAGE_ID:
      return {
        ...state,
        imageId: action.value
      };
    case SET_FILTER_LIGHT_ID:
      return {
        ...state,
        lightId: action.value
      };
    case SET_FILTER_STEP_ID:
      return {
        ...state,
        stepId: action.value
      };
    case SET_FILTER_RECIPE_ID:
      return {
        ...state,
        recipeId: action.value
      };
    case SET_FILTER_CAMERA_NUMBER:
      return {
        ...state,
        cameraNumber: action.value
      };
    case SET_FILTER_PHOTO_SESSION_ID:
      return {
        ...state,
        photoSessionId: action.value
      };
    case SET_FILTER_SPIN_ANGLE:
      return {
        ...state,
        spinAngle: action.value
      };
    case SET_FILTER_SPIN_ANGLE_MIN:
      return {
        ...state,
        spinAngle: [action.value, state.spinAngle[1]]
      };
    case SET_FILTER_SPIN_ANGLE_MAX:
      return {
        ...state,
        spinAngle: [state.spinAngle[0], action.value]
      };
    case TOOGLE_FILTER_TAGS:
        return {
            ...state,
            tags: {
                ...state.tags,
                [action.field]: !state.tags[action.field]
            }
        };
    case SET_FILTER_TAGS:
      let newTags = {}
      action.value.forEach(tag => {
        newTags = {...newTags, [tag]: state.tags[tag] ? true : false}
      });
        return {
            ...state,
            tags: newTags
        };
    case ADD_TAG:
      return {
        ...state,
        tags: {
          ...state.tags,
          [action.tag]: false
        }
      }
    case RESET_FILTER:
      return initial;
    default:
      return state;
  }
}

export default filterReducer;
