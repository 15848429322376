import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddToPhotos from "@material-ui/icons/AddToPhotos";
import FilterNone from "@material-ui/icons/FilterNone";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import DescriptionIcon from "@material-ui/icons/Description";

const styles = theme => ({
  root: {
    height: 380
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: theme.spacing(3)
  }
});



class ActionMenu extends React.Component {
  state = {
    open: false,
    hidden: false
  };

  handleVisibility = () => {
    this.setState(state => ({
      open: false,
      hidden: !state.hidden
    }));
  };

  handleClick = () => {
    this.setState(state => ({
      open: !state.open
    }));
  };

  handleOpen = () => {
    if (!this.state.hidden) {
      this.setState({
        open: true
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const {
      classes,
      selectAllHandler,
      clearAllHandler,
      downloadHandler,
      metadataDownloadHandler,
      addTagHandler,
      removeTagHandler,
      isEverythingSelected,
      isAnythingSelected,
      downloadInProgress,
    } = this.props;
    const { open } = this.state;

const actions = [
  { icon: <AddToPhotos />, name: "Add Tag", disabled: !isAnythingSelected },
  { icon: <FilterNone />, name: "Remove Tag", disabled: !isAnythingSelected },
  {
    icon: <CloudDownload />,
    name: "Download",
    disabled: !isAnythingSelected || downloadInProgress
  },
  {
    icon: <DescriptionIcon />,
    name: "Metadata Download",
    disabled: !isAnythingSelected || downloadInProgress
  },
  {
    icon: isEverythingSelected ? <CheckBoxOutlineBlank /> : <CheckBox />,
    name: isEverythingSelected ? "Unselect All" : "Select All",
    disabled: false
  }
];

    const actionHandlers = {
      "Add Tag": addTagHandler,
      "Remove Tag": removeTagHandler,
      "Download": downloadHandler,
      "Metadata Download": metadataDownloadHandler,
      "Select All": () => selectAllHandler(true),
      "Unselect All": () => clearAllHandler()
    };

    return (
      <div className={classes.root}>
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          className={classes.speedDial}
          icon={<SpeedDialIcon />}
          onBlur={this.handleClose}
          onClick={this.handleClick}
          onClose={this.handleClose}
          onFocus={this.handleOpen}
          onMouseEnter={this.handleOpen}
          onMouseLeave={this.handleClose}
          open={open}
        >
          {actions.map(action => (
            <SpeedDialAction
              key={action.name}
              disabled={action.disabled}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              ButtonProps={{ onClick: actionHandlers[action.name]}}
              onClick={actionHandlers[action.name]}
            />
          ))}
        </SpeedDial>
      </div>
    );
  }
}

ActionMenu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ActionMenu);
