import React, { Component } from "../../../node_modules/react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "../../../node_modules/prop-types";
import {
  List,
  ListItem,
  Button
} from "../../../node_modules/@material-ui/core";
import { withStyles } from "../../../node_modules/@material-ui/core/styles";
import {
  setFilterWeight,
  setFilterElevationAngle,
  setFilterEndDate,
  setFilterStartDate,
  setFilterDimensionsHeight,
  setFilterDimensionsLength,
  setFilterDimensionsWidth,
  setFilterTags,
  setFilterCameraNumber,
  setFilterPhotoSessionId,
  setFilterLightId,
  setFilterRecipeId,
  setFilterStepId,
  setFilterSpinAngle,
  setFilterImageId,
  toogleFilterTags,
  resetFilter,
  getTags
} from "./filterActions";
import {
  collapsibleMultiRange,
  collapsibleDatePicker,
  collapsibleTimePicker,
  collapsibleDimensionFilter,
  collapsibleIdTextboxes
} from "./collapsibleFilterElements";
import { getImageMetadata, clearImageSelection } from "./imageActions";
import {
  elevationAngleSchema,
  spinAngleSchema,
  weightSchema,
  dimensionSchema
} from "./validationSchema";
import { CollapsibleTags } from "./collapsibleTags";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  list: {
    width: "100%"
  },
  nested: {
    paddingLeft: theme.spacing(4),
    marginRight: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap"
  },
  slider: {
    width: "80%"
  },
  listItemText: {
    fontFamily: "BoschSansRegular"
  },
  numberField: {
    fontFamily: "BoschSansRegular",
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  fieldInput: {
    fontFamily: "BoschSansRegular"
  },
  textField: {
    fontFamily: "BoschSansRegular",
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "80%"
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },

  resetButton: {},
  form: {
    width: "40%",
    display: "inline-block"
  },
  errorMessage: {
    display: "block",
    fontFamily: "BoschSans",
    color: "#ea0016"
  }
});

class Filterbar extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      weight: { min: 0, max: 200 },
      startDateTime: new Date(),
      endDateTime: new Date(),
      tags: {},
      elevationAngle: { min: 0, max: 200 },
      dimensions: {
        length: [0, 100],
        height: [0, 80],
        width: [0, 50]
      },
      open: {
        nameField: false,
        weightFilter: false,
        datePicker: false,
        timePicker: false,
        metakeywords: false,
        elevationAngle: false,
        dimensions: false,
        idFilter: false,
        spinAngle: false
      }
    };
  }

  componentDidMount() {
    this.props.getTags();
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleOpen = name => () => {
    this.setState(state => ({
      open: { ...state.open, [name]: !state.open[name] }
    }));
  };

  onSubmitFilter = setFilterValue => values => {
    setFilterValue([values.min, values.max]);
    this.props.getImageMetadata();
    this.props.clearImageSelection();
  };

  onSliderChange = setFilterValue => values => {
        setFilterValue(values);
        this.props.getImageMetadata();
        this.props.clearImageSelection();
  }

  handleValue = name => value => {
    this.setState({ [name]: value });
  };

  handleCheckboxes = name => field => event => {
    event.persist();
    this.props.toogleFilterTags(field);
    this.props.getImageMetadata();
    this.props.clearImageSelection();
  };

  handleIdTextboxes = id => event => {
    event.persist();
    let value = event.target.value;
    console.log(event);
    console.log(value);
    switch (id) {
      case "RecipeId":
        this.props.setFilterRecipeId(value);
        break;
      case "ImageId":
        this.props.setFilterImageId(value);
        break;
      case "CameraNumber":
        this.props.setFilterCameraNumber(value);
        break;
      case "PhotoSessionId":
        this.props.setFilterPhotoSessionId(value);
        break;
      case "LightId":
        this.props.setFilterLightId(value);
        break;
      case "StepId":
        this.props.setFilterStepId(value);
        break;
      default:
    }
    this.props.getImageMetadata();
    this.props.clearImageSelection();
  };

  handleAcceptDateTime = value => {
    this.props.getImageMetadata();
    this.props.clearImageSelection();
  }


  handleStartDateTimeUpdates = value => {
    this.props.setFilterStartDate(value);
  };

  handleEndDateTimeUpdates = value => {
    this.props.setFilterEndDate(value);
  };

  handleResetFilter = () => {
    this.props.resetFilter();
    this.props.getTags();
    this.props.getImageMetadata();
    this.props.clearImageSelection();
  };

  render() {
    const { classes, filter, tagColorMapping} = this.props;
    const idValues = {
      RecipeId: filter.recipeId,
      ImageId: filter.imageId,
      CameraNumber: filter.cameraNumber,
      PhotoSessionId: filter.photoSessionId,
      LightId: filter.lightId,
      StepId: filter.stepId
    };
    const dimensionHandlers = {
      length: this.onSubmitFilter(this.props.setFilterDimensionsLength),
      width: this.onSubmitFilter(this.props.setFilterDimensionsWidth),
      height: this.onSubmitFilter(this.props.setFilterDimensionsHeight)
    };
    return (
      <div className={classes.container}>
        <List className={classes.list}>
          <ListItem key={"filter-reset-button"}>
            <Button
              variant="contained"
              onClick={this.handleResetFilter}
              className={classes.resetButton}
              fullWidth
            >
              {" "}
              Reset filter{" "}
            </Button>
          </ListItem>
          {collapsibleDatePicker(
            "datePicker",
            filter.startDateTime,
            filter.endDateTime,
            "Date Picker",
            this.state.open.datePicker,
            classes,
            this.handleOpen("datePicker"),
            this.handleStartDateTimeUpdates,
            this.handleEndDateTimeUpdates,
            this.handleAcceptDateTime
          )}
          {collapsibleTimePicker(
            "timePicker",
            filter.startDateTime,
            filter.endDateTime,
            "Time Picker",
            this.state.open.timePicker,
            classes,
            this.handleOpen("timePicker"),
            this.handleStartDateTimeUpdates,
            this.handleEndDateTimeUpdates,
            this.handleAcceptDateTime
          )}
          {collapsibleIdTextboxes(
            "ids",
            idValues,
            "Capturing Details",
            this.state.open.idFilter,
            classes,
            this.handleOpen("idFilter"),
            this.handleIdTextboxes
          )}
          {CollapsibleTags(
            "metaKeywords",
            filter.tags,
            tagColorMapping,
            "Tags",
            this.state.open.metakeywords,
            classes,
            this.handleOpen("metakeywords"),
            this.handleCheckboxes("selectedKeywords")
          )}
          {collapsibleMultiRange(
            "elevationAngle",
            filter.elevationAngle,
            "Elevation Angle",
            this.state.open.elevationAngle,
            5,
            0,
            360,
            classes,
            this.handleOpen("elevationAngle"),
            this.onSliderChange(this.props.setFilterElevationAngle),
            elevationAngleSchema,
            this.onSubmitFilter(this.props.setFilterElevationAngle)
          )}
          {collapsibleMultiRange(
            "spinAngle",
            filter.spinAngle,
            "Spin Angle",
            this.state.open.spinAngle,
            5,
            0,
            360,
            classes,
            this.handleOpen("spinAngle"),
            this.onSliderChange(this.props.setFilterSpinAngle),
            spinAngleSchema,
            this.onSubmitFilter(this.props.setFilterSpinAngle)
          )}
          {collapsibleMultiRange(
            "weight",
            filter.weight,
            "Weight in g",
            this.state.open.weightFilter,
            10,
            0,
            250000,
            classes,
            this.handleOpen("weightFilter"),
            this.onSliderChange(this.props.setFilterWeight),
            weightSchema,
            this.onSubmitFilter(this.props.setFilterWeight)
          )}
          {collapsibleDimensionFilter(
            "dimensions",
            filter.dimensions,
            "Dimensions in mm",
            this.state.open.dimensions,
            classes,
            this.handleOpen("dimensions"),
            this.onSliderChange(this.props.setFilterDimensionsHeight),
            this.onSliderChange(this.props.setFilterDimensionsWidth),
            this.onSliderChange(this.props.setFilterDimensionsLength),
            dimensionSchema,
            dimensionHandlers
          )}
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filter: state.filter,
  tagColorMapping: state.tag.tagColorMapping
});

const mapDispatchToProps = dispatch => ({
  setFilterWeight: bindActionCreators(setFilterWeight, dispatch),
  setFilterElevationAngle: bindActionCreators(
    setFilterElevationAngle,
    dispatch
  ),
  setFilterEndDate: bindActionCreators(setFilterEndDate, dispatch),
  setFilterStartDate: bindActionCreators(setFilterStartDate, dispatch),
  setFilterDimensionsHeight: bindActionCreators(
    setFilterDimensionsHeight,
    dispatch
  ),
  setFilterDimensionsLength: bindActionCreators(
    setFilterDimensionsLength,
    dispatch
  ),
  setFilterDimensionsWidth: bindActionCreators(
    setFilterDimensionsWidth,
    dispatch
  ),
  setFilterTags: bindActionCreators(setFilterTags, dispatch),
  toogleFilterTags: bindActionCreators(toogleFilterTags, dispatch),
  setFilterCameraNumber: bindActionCreators(setFilterCameraNumber, dispatch),
  setFilterImageId: bindActionCreators(setFilterImageId, dispatch),
  setFilterSpinAngle: bindActionCreators(setFilterSpinAngle, dispatch),
  setFilterStepId: bindActionCreators(setFilterStepId, dispatch),
  setFilterRecipeId: bindActionCreators(setFilterRecipeId, dispatch),
  setFilterLightId: bindActionCreators(setFilterLightId, dispatch),
  setFilterPhotoSessionId: bindActionCreators(
    setFilterPhotoSessionId,
    dispatch
  ),
  getImageMetadata: bindActionCreators(getImageMetadata, dispatch),
  resetFilter: bindActionCreators(resetFilter, dispatch),
  getTags: bindActionCreators(getTags, dispatch),
  clearImageSelection: bindActionCreators(clearImageSelection, dispatch)
});

Filterbar.propTypes = {
  classes: PropTypes.object.isRequired
};

let Filter = connect(
  mapStateToProps,
  mapDispatchToProps
)(Filterbar);

export default withStyles(styles)(Filter);
