import React from "react";
import { BottomNavigation, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import "./footer.css";

const styles = (theme) => ({});

function Footer(classes) {
    return (
            <BottomNavigation style={{ position: "absolute", bottom: 0, left: 0, right: 0, height: "20px" }}>
                <div className='footer-text'>© 2022 grow platform GmbH</div>
                <Link
                    href='https://www.bosch-cognitive-services.com/corporate-information/'
                    underline='none'
                    className='footer-text'
                    color='inherit'
                >
                    {"Corporate Information"}
                </Link>
                <Link
                    href='https://www.bosch-cognitive-services.com/legal-notice/'
                    underline='none'
                    className='footer-text'
                    color='inherit'
                >
                    {"Legal notice"}
                </Link>
                <Link
                    href='https://www.bosch-cognitive-services.com/data-protection-policy/'
                    underline='none'
                    className='footer-text'
                    color='inherit'
                >
                    {"Privacy statement"}
                </Link>
                <Link
                    href='https://www.bosch-cognitive-services.com/media/images/terms-and-conditions-license-2022-bcs.pdf'
                    underline='none'
                    className='footer-text'
                    color='inherit'
                >
                    {"Terms & Conditions SaaS"}
                </Link>
                <Link
                    href='https://www.bosch-cognitive-services.com/#'
                    underline='none'
                    className='footer-text'
                    color='inherit'
                >
                    {"Privacy settings"}
                </Link>
            </BottomNavigation>
    );
}

export default withStyles(styles)(Footer);