import { OPEN_DOWNLOAD_ALERT, DOWNLOAD_IN_PROGRESS } from "./downloadAction";

const initial = {
  openDownloadAlert: false,
  downloadInProgress: false
};

function downloadReducer(state = initial, action) {
  switch (action.type) {
    case OPEN_DOWNLOAD_ALERT:
      return {
        ...state,
        openDownloadAlert: action.open
      };
    case DOWNLOAD_IN_PROGRESS:
      return {
        ...state,
        downloadInProgress: action.inProgress
      };
    default:
      return state;
  }
}

export default downloadReducer;
