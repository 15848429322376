import React from "react";
import { Card, CardContent } from "@material-ui/core";

export function ObjectCounter(props) {
  const { data, classes } = props;
  return (
    <Card className={classes.objectCounter}>
      <CardContent className={classes.counterContent}>
        <table>
          <tbody>
            <tr>
              <td>Number of Objects:</td>
              <td align="right">{data ? data.length : 0}</td>
            </tr>
            <tr>
              <td>Number of Images:</td>
              <td align="right">{data ? data.length : 0}</td>
            </tr>
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
}

export function ImageCounter(props) {
  const { data, classes } = props;
  return (
    <Card className={classes.objectCounter}>
      <CardContent className={classes.counterContent}>
        <table>
          <tbody>
            <tr>
              <td>Number of Objects:</td>
              <td align="right">{data ? Object.keys(data).length : 0}</td>
            </tr>
            <tr>
              <td>Number of Images:</td>
              <td align="right">
                {data
                  ? Object.keys(data).reduce(
                      (accumulator, objectKey) =>
                        accumulator + data[objectKey].length,
                      0
                    )
                  : 0}
              </td>
            </tr>
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
}