import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Select,
  MenuItem
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";

const styles = theme => ({
  input: {
    fontFamily: "BoschSans"
  }
});

function RemoveTagDialog(props) {
  const { classes, onClose, onUpdate, open, getTags } = props;
    const tags = getTags();
  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        initialValues={{ tag: "" }}
        onSubmit={onUpdate}
        render={renderForm({ onClose, classes, tags })}
      />
    </Dialog>
  );
}

let renderForm = props => ({
  values,
  touched,
  errors,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit
}) => {
  const { onClose, tags } = props;
  return (
    <Form>
      <DialogTitle
        id="edit-form-dialog-title"
        style={{ fontFamily: "BoschSans" }}
      >
        Add Tags to Images
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontFamily: "BoschSans" }}>
          Remove the tag from all selected images:
        </DialogContentText>
        <Select
          name="tag"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.tag}
          fullWidth
        >
          <MenuItem key="" value="" style={{ fontFamily: "BoschSans" }}>
            None
          </MenuItem>
          {tags.map(tag => (
            <MenuItem key={tag} value={tag} style={{ fontFamily: "BoschSans" }}>
              {tag}
            </MenuItem>
          ))}
        </Select>
        {errors.tag && touched.tag && (
          <div style={{ fontFamily: "BoschSans" }}> {errors.tag} </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          style={{ fontFamily: "BoschSans" }}
        >
          Cancel
        </Button>
        <Button
          onClick={onClose}
          color="primary"
          style={{ fontFamily: "BoschSans" }}
          type="submit"
          disabled={isSubmitting}
        >
          Remove
        </Button>
      </DialogActions>
    </Form>
  );
};

export default withStyles(styles)(RemoveTagDialog);
