import React from "react";
import PropTypes from "prop-types";
import LandingImage from "../../resource/Bild_Maschine.png";
import Supergraphic from "../../resource/Bosch-Supergraphic.svg";
import BoschLogo from "../../resource/BOSCH_ENGLISH_RGB.png";
import { Grid, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import "./landing.css";
import Footer from "../Footer";

const styles = theme => ({
  bannerImage: {
    width: "100%",
    height: "10px",
    visibility: "hidden"
  },
  supergraphic: {
    backgroundImage: "url(" + Supergraphic + ")",
    width: "100%",
    height: "20px",
    marginBottom: "5px"
  },
  primary: {
    marginTop: "15px",
    width: "200px",
    height: "50px",
    color: theme.palette.primary.contrastText,
    backgroundColor: "#005691",
    "&:hover": {
      backgroundColor: "#005691",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#005691"
      }
    }
  }
});

function LandingPage(props) {
  const { classes, loginFunction } = props;
  return (
      <div>
          {makeHeader(classes)}
          <Grid container direction='column' alignItems='center'>
              <Grid item>
                  <img className='responsiveimage' src={LandingImage} alt={"Bosch Cognitive Services"} />
              </Grid>
              <Grid item>
                  <Button variant='extended' color='primary' onClick={loginFunction} className={classes.primary}>
                      Login
                  </Button>
              </Grid>
          </Grid>
          <Footer/>
      </div>
  );
}



function makeHeader(classes) {
  return (
      <header>
          <div className={classes.supergraphic} />
          <Grid container justify='center'>
              <Grid item container justify='center' wrap='nowrap'>
                  <Grid item>
                      <img src={BoschLogo} alt={"Bosch Logo"} className='Bosch-logo' />
                  </Grid>
                  <Grid item>
                      <div className='blocker' />
                  </Grid>
                  <Grid item>
                      <h1 className='Text_serviceIdentifier'> BCS Image Portal</h1>
                  </Grid>
              </Grid>
          </Grid>
      </header>
  );
}

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LandingPage);
